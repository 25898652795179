
import { defineComponent, reactive, onMounted, onBeforeUnmount, toRefs } from 'vue'
import { email, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { resetPassword } from '@/expressway-api/auth.api'
import useSnackbar from '@/composables/useSnackbar'
import Spinner from '@/components/Spinner.vue'
import { useRouter } from 'vue-router'
import { AxiosError } from 'axios'
import { loadRecaptcha, unloadRecaptcha } from '@/utils/loadRecaptcha'

declare const window: any

export default defineComponent({
  name: 'ForgotPassword',
  components: { Spinner },
  setup () {
    const { setSnackbar } = useSnackbar()
    const router = useRouter()

    const state = reactive({
      email: '',
      loadingResetPassword: false
    })
    const rules = {
      email: { required, email }
    }

    onMounted(() => {
      loadRecaptcha(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY)
        .catch(() => {
          setSnackbar('Error loading reCAPTCHA. Please try again later.')
        })
    })

    onBeforeUnmount(() => {
      unloadRecaptcha()
    })

    const resetPasswordCall = async () => {
      if (!window.grecaptcha) {
        setSnackbar('Error loading reCAPTCHA. Please try again later.')
        return
      }

      state.loadingResetPassword = true
      const grecaptcha = window.grecaptcha || {}
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'reset_password' })
          .then((token: any) => {
            resetPassword(state.email, token)
              .then(() => {
                router.push({ name: 'ForgotPasswordSuccess' })
              })
              .catch((e: AxiosError) => {
                state.loadingResetPassword = false
                setSnackbar(e.message)
              })
          })
          .catch(() => {
            state.loadingResetPassword = false
            setSnackbar('Error loading reCAPTCHA. Please try again later.')
          })
      })
    }

    return {
      v$: useVuelidate(rules, state),
      resetPasswordCall,
      ...toRefs(state)
    }
  }
})
